import { hasValue } from "@xxl/common-utils";
import type { ProductListingSmallBanner } from "@xxl/content-api";
import { Text } from "../../components/Text";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { useBreakpoint } from "../../hooks/useBreakpoint/useBreakpoint";
import { withErrorBoundary } from "../../utils/WithErrorBoundary/with-error-boundary";
import { getImageUrl } from "../../utils/xxl-image";
import { XxlStack } from "../Common/XxlStack";
import { DisclaimerInformation } from "../DisclaimerInformation";
import { GRID_COLUMNS } from "../Filter/FilterBar/constants";
import { Icon } from "../Icon/Icon";
import {
  PRODUCT_LISTING_SMALL_BANNER_CLASS_NAME,
  SMALL_BANNER_LINK,
} from "../ProductList/constants";
import { saveCurrentScrollPosition } from "../Search/SearchScrollHelper";
import {
  BannerContentWrapper,
  BannerImage,
  BannerImageWrapper,
  Item,
  StyledLink,
} from "./ProductListingSmallBanner.styled";
import { SanitizedHtmlComponent } from "react-app/src/components/Common";

type SmallBannerProps = {
  content: ProductListingSmallBanner;
  index: number;
  selectedColumnsNumber?: number;
};

const handleClick = (): void => {
  saveCurrentScrollPosition(document.documentElement.scrollTop);
};

const _ProductListingSmallBannerComponent = ({
  content,
  index,
  selectedColumnsNumber = GRID_COLUMNS.six,
}: SmallBannerProps) => {
  const { t } = useTranslations();
  const bp = useBreakpoint();
  const isMobile = bp === "mobile";
  const getComputedImage = () => {
    if (bp === "mobile" && content.mobileImage !== undefined) {
      return content.mobileImage;
    }
    return content.contentImage;
  };
  const image = getComputedImage();

  return (
    <li key={index}>
      <Item
        backgroundColor={content.colorTheme.value}
        color={content.colorTheme.font}
        columnCount={selectedColumnsNumber}
        data-testid={PRODUCT_LISTING_SMALL_BANNER_CLASS_NAME}
        className={PRODUCT_LISTING_SMALL_BANNER_CLASS_NAME}
      >
        <BannerContentWrapper columnCount={selectedColumnsNumber}>
          <XxlStack height={"100%"} gap={1}>
            {hasValue(content.disclaimerInformation?.description) && (
              <DisclaimerInformation
                buttonText={t("general.close")}
                title={t("general.information")}
                iconSize={26}
                color={content.disclaimerInformation.buttonColor}
                style={{ justifyContent: "left" }}
              >
                {content.disclaimerInformation.description}
              </DisclaimerInformation>
            )}
            <StyledLink
              href={content.link.url}
              onClick={handleClick}
              data-testid={SMALL_BANNER_LINK}
            >
              <XxlStack height={"100%"} gap={1}>
                {hasValue(content.label) && (
                  <Text
                    backgroundColor={content.colorTheme.font}
                    colorCustom={content.colorTheme.value}
                    padding={"8px"}
                    width={"fit-content"}
                    typography="baseMedium"
                  >
                    {content.label}
                  </Text>
                )}
                <Text
                  sizeCustom={
                    selectedColumnsNumber === GRID_COLUMNS.one ? 15 : 24
                  }
                  size="big"
                  typography="baseBold"
                  colorCustom={content.colorTheme.font}
                  overflowWrap="break-word"
                >
                  <SanitizedHtmlComponent text={content.heading} />
                </Text>
              </XxlStack>
              <XxlStack gap={1} direction="row" alignItems="center">
                <Text
                  sizeCustom={isMobile ? 12 : 15}
                  colorCustom={content.colorTheme.font}
                  typography={"baseMedium"}
                  size="base"
                >
                  {content.link.displayName}
                </Text>
                <Icon name="ArrowRight" />
              </XxlStack>
            </StyledLink>
          </XxlStack>
        </BannerContentWrapper>
        <BannerImageWrapper columnCount={selectedColumnsNumber}>
          <BannerImage
            columnCount={selectedColumnsNumber}
            src={getImageUrl(image)}
            alt={content.contentImage.alt}
          />
        </BannerImageWrapper>
      </Item>
    </li>
  );
};

export const ProductListingSmallBannerComponent = withErrorBoundary(
  _ProductListingSmallBannerComponent
);
