import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import type { DeviceProps } from "../../../global";
import { ButtonStyledAsLink } from "../../../styled";
import { xxlTheme } from "../../../styles/xxl-theme";

const { colors, spaces, typography } = xxlTheme;

type ShowMoreButtonProps = DeviceProps;

export const List = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ShowMore = styled(ButtonStyledAsLink)<ShowMoreButtonProps>(
  ({ isMobile }) => css`
    ${isMobile
      ? css`
          ${typography.smallRegular}
          display: block;
          width: 100%;
          line-height: 32px;
          text-align: center;
          text-decoration: none;
        `
      : css`
          border: 1px solid ${colors.xxlGrey};
          text-decoration: none;
          margin-top: ${spaces.large};
        `}
  `
);
