import styled from "@emotion/styled/macro";
import {
  Pagination as MuiPagination,
  PaginationItem,
  type PaginationRenderItemParams,
} from "@mui/material";
import { color } from "@xxl/theme";
import { XxlButton } from "react-app/src/components/Common/XxlButton";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import spaces from "react-app/src/styles/theme/spaces";

export type PaginationProps = {
  disabled?: boolean;
  onChange: (pageNr: number) => void;
  page: number;
  pageCount: number;
  relativePagingUrl?: string;
};

const muiStyleOverrides = {
  ".MuiPagination-ul": {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  ".MuiPaginationItem-root": {
    borderRadius: 0,
    height: spaces.large,
    width: spaces.large,
    minWidth: spaces.large,
    boxSizing: "border-box",
  },
  ".MuiPaginationItem-root:hover": {
    backgroundColor: color.green.hex,
  },
  ".MuiPaginationItem-root.Mui-selected": {
    backgroundColor: color.green.hex,
  },
  ".MuiPaginationItem-previousNext": {
    backgroundColor: color.webBlack.hex,
    color: color.white.hex,
    margin: 0,
  },
  ".MuiPaginationItem-previousNext:hover": {
    color: color.black.hex,
  },
};

const LinkPaginationItemLink = styled.a<{
  selected: boolean;
  disabled: boolean;
}>`
  div {
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.5rem;
    min-height: 1.5rem;
    min-width: 0.75rem;
    padding-left: ${spaces.miniMicro};
    padding-right: ${spaces.miniMicro};
    ${({ selected }) =>
      selected ? `background-color: ${color.green.hex};` : ""}
  }

  :hover div {
    ${({ disabled }) =>
      disabled ? "" : `background-color: ${color.green.hex};`}
  }
  :focus-visible div {
    background-color: ${color.gray.hex};
  }
`;

const LinkPaginationItem = ({
  onClick,
  page,
  selected,
  disabled,
  relativePagingUrl,
  "aria-current": ariaCurrent,
  "aria-label": ariaLabel,
}: PaginationRenderItemParams & {
  relativePagingUrl?: string;
  "aria-current"?: "page";
  "aria-label"?: string;
}) => (
  <LinkPaginationItemLink
    onClick={(event) => {
      event.preventDefault();
      return onClick(event);
    }}
    selected={selected}
    disabled={disabled}
    role={disabled ? undefined : "button"}
    tabIndex={disabled ? undefined : 0}
    href={relativePagingUrl ?? `?pages=${page ?? 0}`}
    aria-current={ariaCurrent}
    aria-label={ariaLabel}
  >
    <div>{page ?? 0}</div>
  </LinkPaginationItemLink>
);

const Pagination = ({
  onChange,
  page,
  pageCount,
  disabled = false,
  relativePagingUrl,
}: PaginationProps) => {
  const { t } = useTranslations();
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");
  const handleOnChange = (pageNumber: number) => {
    onChange(pageNumber);
  };

  return (
    <XxlStack width={"100%"} flexDirection={"row"} justifyContent={"center"}>
      <XxlStack
        gap={spaces.large}
        width={isLaptop ? "440px" : "351px"}
        paddingBottom={spaces.smallHuge}
        paddingTop={spaces.smallHuge}
      >
        <XxlButton
          href={page !== pageCount ? relativePagingUrl : undefined}
          variant={"secondary"}
          disabled={page === pageCount || disabled}
          onClick={(e) => {
            e.preventDefault();
            handleOnChange(page + 1);
          }}
          data-testid={"plp-next-page-btn"}
        >
          {t("general.next.page")}
        </XxlButton>
        <MuiPagination
          page={page}
          sx={muiStyleOverrides}
          count={pageCount}
          onChange={(_, pageNr) => handleOnChange(pageNr)}
          disabled={disabled}
          renderItem={(item) =>
            item.type === "page" ? (
              <LinkPaginationItem
                {...item}
                relativePagingUrl={relativePagingUrl}
              />
            ) : (
              <PaginationItem {...item} />
            )
          }
        />
      </XxlStack>
    </XxlStack>
  );
};

export { Pagination };
