import styled from "@emotion/styled/macro";
import React from "react";
import stringFormat from "string-format";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { ButtonStyledAsLink } from "../../../styled";
import { xxlTheme } from "../../../styles/xxl-theme";
import { scrollToFilterBar } from "../../../utils/xxl-scroll-to-filter-bar";
import { useFilterContext } from "../../Filter/FilterState";
import { RemoveAllFiltersStyled } from "../FilterBar/FilterBar.styled";
import { closeDropdownFilter, removeStickyFilter } from "../FilterHelper";

const { colors, typography } = xxlTheme;

const Button = styled(ButtonStyledAsLink)`
  ${typography.mediumMedium}
  white-space: nowrap;
  text-decoration: underline;
  color: ${colors.xxlDarkGrey};
`;

export const RemoveAllFilters = ({
  numberOfSelectedFilters,
  onclick,
  variant,
}: {
  numberOfSelectedFilters: number;
  onclick: () => void;
  variant: "button" | "link";
}) => {
  const { t } = useTranslations();
  const { dispatch: filterDispatch, state: filterState } = useFilterContext();

  if (numberOfSelectedFilters === 0) {
    return null;
  }

  const { isStickyFilterBar } = filterState;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    closeDropdownFilter(filterDispatch);
    if (isStickyFilterBar) {
      removeStickyFilter();
      scrollToFilterBar();
    }
    onclick();
  };

  const button = (
    <Button onClick={handleClick} data-testid="filter-remove-all-btn">
      {stringFormat(
        t("filters.remove.count"),
        numberOfSelectedFilters.toString()
      )}
    </Button>
  );

  return variant === "button" ? (
    <RemoveAllFiltersStyled>{button}</RemoveAllFiltersStyled>
  ) : (
    button
  );
};
