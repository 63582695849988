import { hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import type {
  GridCampaignCarousel,
  GridCarousel,
  OneCardContentComponent,
} from "@xxl/content-api";
import { log } from "@xxl/logging-utils";
import { useEffect, useState } from "react";
import { useApiClients } from "../../contexts/ApiClients";
import { useSharedData } from "../../contexts/SharedData";
import { legacySiteUidToSiteUid } from "../../utils/xxl-shared-data";

type GridExtrasData = {
  productsRecommendationsCarousel: GridCarousel | undefined;
  campaignCarousel: GridCampaignCarousel | undefined;
  oneCardContentComponents: OneCardContentComponent[];
};

export function useGridExtras(
  relativePageUrl: string | null,
  togglePlpOneCardContentComponent: boolean
) {
  const [gridExtrasData, setGridExtrasData] = useState<GridExtrasData>();
  const { pageType, siteUid: legacySiteUid } = useSharedData().data;

  const { contentApi } = useApiClients();

  useEffect(() => {
    const fetchGridExtras = async () => {
      if (
        pageType === "brand" ||
        pageType === "category" ||
        pageType === "campaign" ||
        pageType === "search"
      ) {
        let url = relativePageUrl;
        if (typeof window !== "undefined" && relativePageUrl === null) {
          url = window.location.pathname;
        }
        const siteUid = legacySiteUidToSiteUid(legacySiteUid);

        const code =
          pageType === "search"
            ? undefined
            : pageType === "brand"
              ? url?.split("/").slice(-2)[0]
              : url?.split("/").pop()?.split("?").shift();
        const { data } = await contentApi.getPlpComponents(
          siteUid,
          pageType,
          hasValue(code) ? decodeURI(code) : code
        );
        if (isNotNullOrUndefined(data)) {
          const gridExtras: GridExtrasData = {
            productsRecommendationsCarousel: data.gridCarousels[0],
            campaignCarousel: data.gridCampaignCarousels[0],
            oneCardContentComponents: data.oneCardContentComponents,
          };
          setGridExtrasData(gridExtras);
        }
      }
    };
    try {
      void fetchGridExtras();
    } catch (error) {
      log.error(error);
    }
  }, [
    pageType,
    contentApi,
    legacySiteUid,
    relativePageUrl,
    togglePlpOneCardContentComponent,
  ]);

  return {
    gridExtrasData,
  };
}
