import { hasNoValue, hasValue } from "@xxl/common-utils";
import type { CategoryData, HighlightedCategoryData } from "@xxl/frontend-api";

const mapHighlightedCategoriesToCategoryData = ({
  categories,
  highlightedCategories,
}: {
  categories: CategoryData[];
  highlightedCategories: HighlightedCategoryData[];
}) =>
  highlightedCategories.flatMap((c) => {
    const {
      code: categoryCode,
      name,
      url,
    } = categories.find(({ code }) => code === c.categoryCode) ?? {};
    if (hasNoValue(categoryCode) || hasNoValue(name) || hasNoValue(url)) {
      return [];
    }
    return [
      {
        name,
        categoryCode,
        url,
        ...(hasValue(c.image) ? { image: c.image } : undefined),
      },
    ];
  });

export { mapHighlightedCategoriesToCategoryData };
