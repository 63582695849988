import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { MQ } from "../../styles/helpers";
import { xxlTheme } from "../../styles/xxl-theme";
import { GRID_COLUMNS } from "../Filter/FilterBar/constants";

const { colors, spaces } = xxlTheme;

const BANNER_CONTENT_GRID_FOUR_LAPTOP_HEIGHT = "380px"; // to match a gap in the breakpoints for the banner content height
const BANNER_CONTENT_HEIGHT_MAP = {
  [GRID_COLUMNS.one]: "180px",
  [GRID_COLUMNS.two]: "240px",
  [GRID_COLUMNS.four]: "344px",
  [GRID_COLUMNS.six]: "300px",
} as const;

const getBannerContentHeight = (columnCount: number) => {
  switch (columnCount) {
    case GRID_COLUMNS.one:
      return BANNER_CONTENT_HEIGHT_MAP[GRID_COLUMNS.one];
    case GRID_COLUMNS.two:
      return BANNER_CONTENT_HEIGHT_MAP[GRID_COLUMNS.two];
    case GRID_COLUMNS.four:
      return BANNER_CONTENT_HEIGHT_MAP[GRID_COLUMNS.four];
    case GRID_COLUMNS.six:
      return BANNER_CONTENT_HEIGHT_MAP[GRID_COLUMNS.six];
    default:
      return "100%";
  }
};

export const BannerContentWrapper = styled.div<{ columnCount: number }>`
  display: flex;
  flex-direction: column;
  height: ${({ columnCount }) => getBannerContentHeight(columnCount)};
  box-sizing: border-box;
  padding: ${({ columnCount }) =>
    columnCount === GRID_COLUMNS.four ? spaces.large : spaces.smallRegular};

  ${MQ("laptop")} {
    height: ${({ columnCount }) =>
      columnCount === GRID_COLUMNS.four &&
      BANNER_CONTENT_GRID_FOUR_LAPTOP_HEIGHT};
  }
`;

const BANNER_IMAGE_HEIGHT_MAP = {
  [GRID_COLUMNS.one]: "180px",
  [GRID_COLUMNS.two]: "214px",
  [GRID_COLUMNS.four]: "258px",
  [GRID_COLUMNS.six]: "220px",
} as const;

const getImageHeight = (columnCount: number) => {
  switch (columnCount) {
    case GRID_COLUMNS.one:
      return BANNER_IMAGE_HEIGHT_MAP[GRID_COLUMNS.one];
    case GRID_COLUMNS.two:
      return BANNER_IMAGE_HEIGHT_MAP[GRID_COLUMNS.two];
    case GRID_COLUMNS.four:
      return BANNER_IMAGE_HEIGHT_MAP[GRID_COLUMNS.four];
    case GRID_COLUMNS.six:
      return BANNER_IMAGE_HEIGHT_MAP[GRID_COLUMNS.six];
    default:
      return "100%";
  }
};

export const BannerImageWrapper = styled.div<{ columnCount: number }>`
  height: ${({ columnCount }) => getImageHeight(columnCount)};
`;

const GRID_COLUMNS_ONE_WIDTH = "171px";

type ItemProps = {
  backgroundColor?: string;
  color?: string;
  columnCount: number;
};
export const Item = styled.div<ItemProps>(
  ({ backgroundColor, color, columnCount }) => css`
    color: ${color ?? colors.xxlWebBlack};
    background-color: ${backgroundColor ?? colors.xxlGreen};
    display: ${columnCount === GRID_COLUMNS.one ? "grid" : "flex"};
    flex-direction: column;
    ${columnCount === GRID_COLUMNS.one &&
    css`
      grid-template-columns: ${GRID_COLUMNS_ONE_WIDTH} 1fr;
    `}
  `
);

export const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

export const BannerImage = styled.img<{ columnCount: number }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
