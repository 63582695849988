import useMediaQuery from "@mui/material/useMediaQuery";
import { isNullOrUndefined } from "@xxl/common-utils";
import type { ProductListingSmallBanner } from "@xxl/content-api";
import type { ProductListingSmallBannerData } from "@xxl/frontend-api";
import { log } from "@xxl/logging-utils";
import React, { forwardRef, useEffect, useRef } from "react";
import { useSession } from "../../hooks/useSession";
import { useSharedData } from "../../contexts/SharedData";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import type { ProductMetaData } from "../../global";
import {
  mobileAndTabletNotHorizontalMediaQuery,
  mobileMediaQuery,
} from "../../utils/xxl-screen";
import { Product } from "../Product/Product";
import {
  getPriceData,
  getProductType,
  toProductCardData,
  type CombinedProductData,
} from "../Product/product-helper";
import { ProductListingSmallBannerComponent } from "../ProductListingSmallBanner/ProductListingSmallBanner";
import { SET_HIDDEN_PRODUCT_ITEMS_NUMBER } from "../Search/Constants";
import { useSearchContext } from "../Search/SearchState";
import { ListWrapper } from "./ProductList.styled";
import { getProductListPrice } from "./ProductListPrice";
import { NR_OF_PRODUCTS_BETWEEN_CATEGORY_CONTENT } from "./constants";
import { useGridExtras } from "./useGridExtras";

type ProductListProps = {
  products: CombinedProductData[];
  smallBanners: ProductListingSmallBannerData[];
  additionalClasses?: string;
  style?: React.CSSProperties;
  isPersonalized?: boolean;
  selectedColumnsNumber: number;
};

const calculateCategoryContentPosition = (
  products: CombinedProductData[]
): number => {
  const nrOfProductsBetweenCategoryContent =
    NR_OF_PRODUCTS_BETWEEN_CATEGORY_CONTENT;
  const hasFewProducts = nrOfProductsBetweenCategoryContent > products.length;

  return hasFewProducts ? products.length : nrOfProductsBetweenCategoryContent;
};

export const getSmallBanners = (
  smallBanners: (ProductListingSmallBannerData | undefined)[],
  productIndex: number,
  contentIndexRef: React.MutableRefObject<number>,
  nrOfProductsBetweenCategoryContent: number,
  selectedColumnsNumber: number
): JSX.Element | null => {
  const showContentAtThisPosition =
    (productIndex % nrOfProductsBetweenCategoryContent) + 2 ===
    nrOfProductsBetweenCategoryContent - 2;
  const hasRemainingContent = contentIndexRef.current < smallBanners.length;

  if (hasRemainingContent && showContentAtThisPosition) {
    const content = smallBanners[contentIndexRef.current];
    contentIndexRef.current += 1;

    return content !== undefined ? (
      <ProductListingSmallBannerComponent
        key={content.id ?? `${productIndex}-${contentIndexRef.current}`}
        content={content as ProductListingSmallBanner}
        index={contentIndexRef.current}
        selectedColumnsNumber={selectedColumnsNumber}
      />
    ) : null;
  }

  return null;
};

const ProductList = forwardRef<HTMLUListElement, ProductListProps>(
  (
    { products, smallBanners, selectedColumnsNumber: nrOfProductsInRow },
    ref
  ) => {
    const { state, dispatch } = useSearchContext();
    const { t } = useTranslations();
    const {
      featureToggles: { toggle_products_as_package_quantity },
      pageType,
      siteDefaultLanguage,
    } = useSharedData().data;
    const {
      sessionState: { isLoggedIn },
    } = useSession();
    const {
      fetchMoreProductsCount,
      isCampaignPage,
      page,
      togglePlpOneCardContentComponent,
      relativePageUrl,
      isFetchingNewSearchResult,
    } = state;
    const showHighlightedLabel = !isCampaignPage;
    const hasSmallBanners = smallBanners.length > 0;
    const nrOfProductsBetweenCategoryContent =
      calculateCategoryContentPosition(products);
    const maxNrOfSmallBannersPerPage =
      products.length / nrOfProductsBetweenCategoryContent;

    const { gridExtrasData } = useGridExtras(
      relativePageUrl,
      togglePlpOneCardContentComponent
    );

    const isMobile = useMediaQuery(mobileMediaQuery);
    const isTablet = useMediaQuery(mobileAndTabletNotHorizontalMediaQuery);
    const isDesktop = !isMobile && !isTablet;

    const smallBannersIndexRef = useRef(0);
    smallBannersIndexRef.current = 0;

    const recommendationsRef = useRef(0);
    recommendationsRef.current = 0;

    const campaignCarouselRef = useRef(0);
    campaignCarouselRef.current = 0;

    const currentPage = page + 1;

    const oneCardContentComponentsRef = useRef(0);
    oneCardContentComponentsRef.current = 0;
    const nrOfOneCardContentComponents =
      gridExtrasData?.oneCardContentComponents.length ?? 0;
    const hasOneCardContentComponents =
      togglePlpOneCardContentComponent && nrOfOneCardContentComponents > 0;

    const bannersOnCurrentPage =
      maxNrOfSmallBannersPerPage * currentPage < smallBanners.length
        ? maxNrOfSmallBannersPerPage
        : smallBanners.length;

    const numberOfCards =
      products.length +
      bannersOnCurrentPage +
      (hasOneCardContentComponents ? nrOfOneCardContentComponents : 0);

    const fullRows = Math.floor(numberOfCards / nrOfProductsInRow);

    const bannersToHide =
      fetchMoreProductsCount !== 0
        ? numberOfCards - fullRows * nrOfProductsInRow
        : 0;

    useEffect(() => {
      dispatch({
        type: SET_HIDDEN_PRODUCT_ITEMS_NUMBER,
        payload: bannersToHide,
      });
    }, [bannersToHide, dispatch]);

    return (
      <ListWrapper
        columnAmount={nrOfProductsInRow}
        data-testid="product-list"
        isLoading={isFetchingNewSearchResult}
        ref={ref}
      >
        {products.map((product, index) => {
          let bannerPosition = nrOfProductsBetweenCategoryContent;
          const hasMoreProducts = Boolean(
            products.length > nrOfProductsBetweenCategoryContent
          );
          const hasMoreProductsSections = Boolean(
            products.length / nrOfProductsBetweenCategoryContent >
              smallBanners.length
          );
          const isSectionWithoutSmallBanner =
            index + 1 >
              smallBanners.length * nrOfProductsBetweenCategoryContent &&
            bannerPosition === nrOfProductsBetweenCategoryContent;
          if (
            hasMoreProducts &&
            hasMoreProductsSections &&
            isSectionWithoutSmallBanner
          ) {
            bannerPosition += 1;
          }

          const hideProduct =
            fetchMoreProductsCount !== 0 &&
            (smallBanners.length > 0 || hasOneCardContentComponents) &&
            index >=
              fullRows * nrOfProductsInRow -
                (smallBanners.length > 0
                  ? bannersOnCurrentPage % nrOfProductsInRow
                  : 0) -
                (hasOneCardContentComponents
                  ? nrOfOneCardContentComponents % nrOfProductsInRow
                  : 0);

          const productMetaData: ProductMetaData = {
            list: "search",
            pageType,
            position: index,
          };

          const productType = getProductType(product);
          const units = "units" in product ? product.units : undefined;
          const { code, priceDisplay } = product;

          if (isNullOrUndefined(priceDisplay)) {
            log.error(
              `Product with code ${code ?? "undefined"} is missing price display.`
            );
            return null;
          }

          const priceData = getPriceData({
            version: 1,
            priceDisplay,
            productType,
            showPackagePrice: false,
            siteDefaultLanguage,
            toggleProductsAsPackageQuantity:
              toggle_products_as_package_quantity,
            units,
          });

          if (priceData === null) {
            log.error(
              `Product with code ${code ?? "undefined"} is missing price data.`
            );
            return null;
          }

          const { colorTheme, highlightedLabel, priceSplash } = priceData;

          return (
            <React.Fragment key={product.code}>
              <li>
                <Product
                  campaignColorThemeName={colorTheme.name ?? "green"}
                  hidden={hideProduct}
                  highlightedLabel={highlightedLabel}
                  isHoverable={isDesktop}
                  isSliderProductList={false}
                  PriceComponent={getProductListPrice({
                    product,
                    selectedColumnsNumber: nrOfProductsInRow,
                    t,
                  })}
                  product={toProductCardData({
                    product,
                    type:
                      "productType" in product ? product.productType : "NORMAL",
                    t,
                    isLoggedIn,
                    ticket: "",
                  })}
                  priceSplash={priceSplash}
                  productMetaData={productMetaData}
                  selectedColumnsNumber={nrOfProductsInRow}
                  selectedFilters={state.selectedFilters}
                  showHighlightedLabel={showHighlightedLabel}
                  prioritizeImageLoad={true}
                  positionInList={index}
                />
              </li>
              {hasSmallBanners
                ? getSmallBanners(
                    smallBanners,
                    index,
                    smallBannersIndexRef,
                    bannerPosition,
                    nrOfProductsInRow
                  )
                : null}
            </React.Fragment>
          );
        })}
      </ListWrapper>
    );
  }
);

ProductList.displayName = "ProductList";

export { ProductList };
