import {
  coordinatesDistance,
  requestUserCoordinates,
} from "../../../../utils/xxl-geolocation";
import typography from "../../../../styles/theme/typography";
import type {
  Store,
  Location as StoreLocation,
} from "@/react-utils/Stores/stores-helper";

export type TRegionalStore = {
  regionIds: string[];
  id: string;
  name: string;
};

const sortDataByGeolocation = (
  stores: Store[],
  userCoordinates: GeolocationPosition
): Store[] => {
  const getDistanceFromUserLocation = (
    userCoordinates: GeolocationPosition,
    location: StoreLocation
  ) => {
    const { latitude, longitude } = location;
    const distanceFromUserLocation = coordinatesDistance.get(
      userCoordinates.coords.latitude,
      userCoordinates.coords.longitude,
      latitude,
      longitude
    );
    return distanceFromUserLocation;
  };

  return stores.sort((storeA, storeB) => {
    const locationA = storeA.location;
    const locationB = storeB.location;

    if (locationA === null || locationB === null) {
      return 0;
    }

    const distanceToA = getDistanceFromUserLocation(userCoordinates, locationA);
    const distanceToB = getDistanceFromUserLocation(userCoordinates, locationB);

    if (distanceToA === undefined || distanceToB === undefined) {
      return 0;
    }

    return distanceToA - distanceToB;
  });
};

const getSortedStoresList = async (stores: Store[]): Promise<Store[]> => {
  try {
    const userCoordinates = await requestUserCoordinates();
    return sortDataByGeolocation(stores, userCoordinates);
  } catch (_error) {
    return stores;
  }
};

const getFontFamily = (isBold: boolean) =>
  isBold
    ? typography.standardBold.fontFamily
    : typography.standardRegular.fontFamily;

export { getSortedStoresList, getFontFamily };
