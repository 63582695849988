import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../../../../../../styles/xxl-theme";
import { FocusableLink } from "../../../../../../Common/Link";

const { colors, spaces } = xxlTheme;

export const Item = styled.span`
  display: flex;
  flex-grow: 1;
  margin: 0 ${spaces.micro};
  color: ${colors.xxlBlack};
  text-align: left;
  word-break: break-word;
`;

export const ColorItem = styled(Item)`
  margin: 0 ${spaces.mini};
`;

export const Link = styled(FocusableLink)`
  z-index: 1;
  display: block;
  width: 100%;
  font-family: var(--font-family-regular);
  line-height: 19px;
  text-decoration: none;
  background: none;
  border: 0;
  cursor: pointer;
  & {
    padding: ${spaces.smallLarge} 0 0 0;
  }
`;
