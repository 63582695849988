import { FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER } from "../../Search/Constants";
import type { SelectorCategory } from "../category-selector.helper";

enum CategoryDataDefaultSortEnum {
  news = "news",
  cheapest = "cheapest",
  expensive = "expensive",
  campaign = "campaign",
  bigProduct = "bigProduct",
  relevance = "relevance",
  highestRating = "highestRating",
  highestDiscount = "highestDiscount",
  registeredHighestDiscount = "registeredHighestDiscount",
}

type CategoryData = {
  breadcrumbs?: CategoryData[];
  categoryLevel?: number;
  code?: string;
  defaultSort?: CategoryDataDefaultSortEnum;
  description?: string;
  discountsSubcategory?: boolean;
  header?: string;
  keywords?: string;
  logo?: string;
  metaContext?: { [key: string]: string };
  metaDescription?: string;
  name?: string;
  pageTitle?: string;
  productCount?: number;
  subCategories?: CategoryData[];
  url?: string;
};

type ModuleDataVariant =
  | "BRAND"
  | "BUYGIFTCARDHEROCOMPONENT"
  | "CATEGORIES"
  | "CONTENTPRODUCTCAROUSEL"
  | "CUSTOMERSERVICEHEROCOMPONENT"
  | "FOURGRIDBANNERS"
  | "FULLGRIDCOMPONENT"
  | "FULLWIDTHBANNER"
  | "GRIDBANNER"
  | "GRIDSECTION"
  | "GUIDECAROUSEL"
  | "HALFGRIDCOMPONENT"
  | "HEROCOMPONENT"
  | "HEROES13"
  | "HIGHLIGHTEDMEDIUMPRODUCTBANNER"
  | "HIGHLIGHTEDSMALLPRODUCTBANNER"
  | "MEDIUMBANNER"
  | "MEDIUMPRODUCTBANNER"
  | "MINIBANNER"
  | "NEWSLETTERSIGNUP"
  | "PARTNERBANNERS"
  | "PRODUCTRECBOTTOM"
  | "PRODUCTRECMIDDLE"
  | "PRODUCTRECOMMENDATION"
  | "PRODUCTRECTOP"
  | "PRODUCTSCOMPONENT"
  | "SHOPINSHOPCATEGORIES"
  | "SHORTDESCRIPTIONHEADLINE"
  | "SIMPLEHTML"
  | "SMALLBANNER"
  | "SMALLPRODUCTBANNER"
  | "STEPSGUIDECOMPONENT"
  | "TEAMSALESHERO"
  | "TWINIMAGECOMPONENT"
  | "TWOCOLUMNBANNERS"
  | "TWOCOLUMNGRID"
  | "USPCOMPONENT"
  | "USPLIST"
  | "USPS"
  | "VIDEOBANNER"
  | "VIDEOCOMPONENT"
  | "QUARTERGRIDCOMPONENT"
  | "XSPRODUCTBANNER"
  | "DUALIMAGE"
  | "FULLWIDTHIMAGE"
  | "IMAGEANDCONTENT"
  | "VIDEOANDDESCRIPTION"
  | "RICHTEXT";

type ModuleData = {
  key?: string;
  type?: ModuleDataVariant;
};

type CampaignLevel1CategoryData = {
  categoryContentsIds?: string[];
  code?: string;
  productListingSmallBannersIds?: string[];
};

type CampaignPageContentData = {
  additionalCampaignIds?: string[];
  bannerHeader?: string;
  bannerImage?: ImageData;
  bannerText?: string;
  campaignDescription?: string;
  campaignId?: string;
  categoryContentsIds?: string[];
  contentModules?: ModuleData[];
  fromDate?: string;
  id?: string;
  ipaperLink?: string;
  lastModified?: string;
  level1Categories?: CampaignLevel1CategoryData[];
  metaDescription?: string;
  pageTitle?: string;
  productListingSmallBannersIds?: string[];
  toDate?: string;
  totalProductsCount?: number;
  url?: string;
};

export type CampaignData = {
  categoryContentsIds?: string[];
  productListingSmallBannersIds?: string[];
  campaignPageContent?: CampaignPageContentData;
  categories: CategoryData[];
  ipaperLink?: string;
  bannerHeader?: string;
  bannerText?: string;
  level1Categories?: CampaignLevel1CategoryData[];
  totalProductsCount: number;
};

export const findCategory = (
  categories: SelectorCategory[],
  categoryPath: string[]
): SelectorCategory => {
  if (categoryPath.length < 1) {
    throw Error(`categoryPath must contain at least one element.`);
  }

  const category = categories.find(({ code }) => code === categoryPath[0]);

  if (category === undefined) {
    throw Error(`Cannot find category with ID '${categoryPath[0]}'`);
  }

  if (categoryPath.length === 1) {
    return category;
  }

  const { subCategories } = category;
  return findCategory(subCategories, [...categoryPath.slice(1)]);
};

export const withoutShowAllParameter = (parameter: string) =>
  parameter !== FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER;
