import { Drawer } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import type { PropsWithChildren } from "react";
import { useState } from "react";
import stringFormat from "string-format";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { xxlTheme } from "../../../styles/xxl-theme";
import { mobileAndTabletNotHorizontalMediaQuery } from "../../../utils/xxl-screen";
import {
  SET_EXPANDED_FILTER_DRAWER,
  SET_EXPANDED_TOP_FILTER,
  UPDATE_DRAWER_FILTER_SELECTED,
  useFilterContext,
} from "../../Filter/FilterState";
import {
  CloseIconStyled,
  FilterHeading,
  ShowProductsButton,
} from "../FilterDrawer/FilterDrawer.styled";
import {
  FilterItemContentScrollStyled,
  FilterItemContentStyled,
  FilterItemHeaderStyled,
  FilterItemStyled,
  MobileFilterDraweFooterStyled,
  MobileFilterDrawerContentStyled,
  MobileFilterDrawerHeaderStyled,
} from "./FilterBar.styled";
import { FilterExpandIcon } from "./FilterExpandIcon";

type FilterItemProps = {
  id: string;
  isFetchingNewSearchResult: boolean;
  isRangeFilter?: boolean;
  name: string;
  scrollToRef?: boolean;
  totalHits: number;
};

export const FilterItem = ({
  id,
  isFetchingNewSearchResult,
  isRangeFilter = false,
  name,
  children,
  totalHits,
}: PropsWithChildren<FilterItemProps>) => {
  const { t } = useTranslations();
  const { dispatch: filterDispatch, state: filterState } = useFilterContext();
  const { expandedFilterDrawer, expandedTopFilter, isStickyFilterBar } =
    filterState;
  const isSelectedFilter = expandedTopFilter === id;
  const isExpanded = isStickyFilterBar
    ? isSelectedFilter && !isFetchingNewSearchResult
    : isSelectedFilter;
  const isColorFilter = id === "style";

  const isMobileOrTablet = useMediaQuery(
    mobileAndTabletNotHorizontalMediaQuery
  );
  const [drawerOpen, setDrawerOpen] = useState(expandedFilterDrawer === id);
  const { zIndex } = xxlTheme;

  const toggleDropdown = () => {
    filterDispatch({
      type: SET_EXPANDED_TOP_FILTER,
      payload: isExpanded ? "" : id,
    });
    filterDispatch({
      type: UPDATE_DRAWER_FILTER_SELECTED,
      payload: false,
    });
  };

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
    filterDispatch({
      type: SET_EXPANDED_FILTER_DRAWER,
      payload: open ? id : "",
    });
    filterDispatch({
      type: UPDATE_DRAWER_FILTER_SELECTED,
      payload: false,
    });
  };

  return isMobileOrTablet ? (
    <FilterItemStyled>
      <FilterItemHeaderStyled onClick={() => toggleDrawer(true)}>
        <span>{name}</span>
        <FilterExpandIcon isExpanded={isExpanded} />
      </FilterItemHeaderStyled>
      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
        sx={{ zIndex: zIndex.modal }}
        keepMounted={true}
      >
        <MobileFilterDrawerHeaderStyled>
          <FilterHeading>{name}</FilterHeading>
          <CloseIconStyled onClick={() => toggleDrawer(false)} />
        </MobileFilterDrawerHeaderStyled>
        <MobileFilterDrawerContentStyled>
          {children}
        </MobileFilterDrawerContentStyled>
        <MobileFilterDraweFooterStyled>
          <ShowProductsButton
            data-testid="mobile-filter-close-button"
            onClick={() => toggleDrawer(false)}
          >
            {stringFormat(t("filters.total.items.show"), totalHits.toString())}
          </ShowProductsButton>
        </MobileFilterDraweFooterStyled>
      </Drawer>
    </FilterItemStyled>
  ) : (
    <FilterItemStyled isExpanded={isExpanded}>
      <FilterItemHeaderStyled onClick={toggleDropdown}>
        <span>{name}</span>
        <FilterExpandIcon isExpanded={isExpanded} />
      </FilterItemHeaderStyled>
      <FilterItemContentStyled
        isExpanded={isExpanded}
        isRangeFilter={isRangeFilter}
        isColorFilter={isColorFilter}
      >
        <FilterItemContentScrollStyled>
          {children}
        </FilterItemContentScrollStyled>
      </FilterItemContentStyled>
    </FilterItemStyled>
  );
};
